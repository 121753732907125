import React from 'react'
import {Link} from 'react-router-dom'
import mainLogo3 from '../img/THM_Logo1.png'


function Header(){
    return(
        <React.Fragment>
                       <div className='space1'></div>
                       <div className='space1'></div>


            <div className='GIF'><img src={mainLogo3} width={70}/></div>

<div className='Header'>Welcome to The Magic House</div> 

                   </React.Fragment>
        
    )
}

export default Header;


//<div className='GIF'><img src={logo} width={40}/></div>
import React from 'react'
import Header from './HeaderComponent'
import Footer from './FooterComponent'

import Home from './HomeComponent'
import Bookstore from './BookstoreComponent'
import Author from './AuthorComponent'
import Contact from './ContactComponent'


import {Switch,Route, Redirect, withRouter} from 'react-router-dom'


function Main(){
    return(
        <div className='Background'>



<div className='Home'>
<React.Fragment>

<div className='Top'>
<Header/>

<div className='Links'>

<Switch>
<Route path='/home' component={Home}/>
<Route path='/bookstore' component={Bookstore}/>
<Route path='/author' component={Author}/>
<Route path='/contact' component={Contact}/>
<Redirect to='/home'/>
</Switch>

</div>

<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>


    
</div>

</React.Fragment>

        </div>
        
        </div>
       
        
    )
}

export default withRouter(Main);



import React from 'react'
import {NavLink} from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Route } from 'react-router-dom'
import mainLogo from '../img/picture-frame1.png'
import mainLogo3 from '../img/coverfrontduck.jpg'
import mainLogo4 from '../img/Vanilla2.svg'
import tiktokp from '../img/tt2.png'
import instap from '../img/insta.png'
import { Container } from 'react-bootstrap'
import ama from '../img/ama.png'
import YoutubeEmbed from "./YoutubeEmbed";

function Home(){
    return(
        
        
        <React.Fragment>
            

            <div className='links'>
        <NavLink to='/home' className='mainnav'>Home{' '}</NavLink>
        <NavLink to='/bookstore' className='mainnav'>Bookstore{' '}</NavLink>
        <NavLink to='/author' className='mainnav'>Author{' '}</NavLink>
        <NavLink to='/contact' className='mainnav'>Contact{' '}</NavLink>
        </div>
        <div className='BelowHome'></div>
        <div className='BelowHome'></div>

 
        <div className='frameframe'><img src={mainLogo} width={290}/></div>
       
       <div className="mirror">
     <YoutubeEmbed embedId="o44bBQjrHc4" className="mirror2"/>
   </div>
       
       <div className='bottomhomehalf'/>


       
        <div className="mirror">
    </div>
        
        <div className='bottomhomehalf'>


        <div className='GIF'><img src={mainLogo4} width={180}/></div>
        <a href="https://www.amazon.com/There-Once-Duck-Dylan-French/dp/B0BCSH4S1L/" target="_blank">
           <div className='GIF'><img src={mainLogo3} width={150}/></div> </a>
        <div className='space1'></div>

        <a href="https://www.amazon.com/There-Once-Duck-Dylan-French/dp/B0BCSH4S1L/" target="_blank">
           <div className='buybutton1'>
<button className='buybutton2'>
    Get this book
</button>
</div> </a>
        

<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>
<div className='space1'></div>


            <a href="https://www.tiktok.com/t/ZTdGVmG59/" target="_blank">
           <div className='tiktokp'><img src={tiktokp} width={30}/></div> </a>
           <a href="https://www.instagram.com/_u/themagichousebooks" target="_blank">
           <div className='instap'><img src={instap} width={30}/></div> </a>
           <a href="https://www.amazon.com/Dylan-French/e/B0B2JGDHKK/ref=dp_byline_cont_pop_book_1" target="_blank">
           <div className='ama'><img src={ama} width={30}/></div> </a>


</div>
        
        </React.Fragment>

    )
}

//        <Link to={{ pathname:"https://amzn.to/3ljYVmB" }}></Link>

export default Home;
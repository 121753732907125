import React from 'react'
import {NavLink} from 'react-router-dom'
import tiktokp from '../img/tt2.png'
import instap from '../img/insta.png'
import { Container } from 'react-bootstrap'
import ama from '../img/ama.png'
import authorp from '../img/author.png'

function Author(){
    return(
        <React.Fragment>
             
            <div className='links'>
        <NavLink to='/home' className='mainnav'>Home{' '}</NavLink>
        <NavLink to='/bookstore' className='mainnav'>Bookstore{' '}</NavLink>
        <NavLink to='/author' className='mainnav'>Author{' '}</NavLink>
        <NavLink to='/contact' className='mainnav'>Contact{' '}</NavLink>
        </div>
        <div className='space1'></div>
        <div className='space1'></div>
        
    
        <div className='authorp'><img src={authorp} width={250}/></div>
       
       <div className='space1'></div>
<div classname="biobio">
    <div></div>
<div> My name is Dylan and I am the creator of The Magic House. 
    Over the past year I have working on the stories 'There Once Was A Duck' and
    ‘The Strawberry Blues’ and it has been such a joy.
    I hope these stories find a special place in your heart
    and remind you of home.
    
</div>
<div className='space1'></div>

<div>
Thank you for being here... Enjoy!
</div>
<div className='space1'></div>
<div>
Welcome to The Magic House. 
</div>
<div className='space1'></div>
<div>
Sincerely, Dylan
</div>
</div>
        <div></div>  

        

        <div className='end'></div>
   
        
            <a href="https://www.tiktok.com/t/ZTdGVmG59/" target="_blank">
           <div className='tiktokp'><img src={tiktokp} width={30}/></div> </a>
           <a href="https://www.instagram.com/_u/themagichousebooks" target="_blank">
           <div className='instap'><img src={instap} width={30}/></div> </a>
           <a href="https://www.amazon.com/Dylan-French/e/B0B2JGDHKK/ref=dp_byline_cont_pop_book_1" target="_blank">
           <div className='ama'><img src={ama} width={30}/></div> </a>

           <div className='end'></div>


        </React.Fragment>
    )
}

export default Author;
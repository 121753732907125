import React from 'react'
import 'bootstrap'
import {NavLink} from 'react-router-dom'
import mainLogo5 from '../img/parchment22.png'
import { Container } from 'react-bootstrap'
import '../css/styles.css'
import tiktokp from '../img/tt2.png'
import instap from '../img/insta.png'
import ama from '../img/ama.png'


function Contact(){
    return(
        <React.Fragment className="contactfrag">
            
                <div className='links'>
        <NavLink to='/home' className='mainnav'>Home{' '}</NavLink>
        <NavLink to='/bookstore' className='mainnav'>Bookstore{' '}</NavLink>
        <NavLink to='/author' className='mainnav'>Author{' '}</NavLink>
        <NavLink to='/contact' className='mainnav'>Contact{' '}</NavLink>
        </div>
        
        <div className='space1'></div>

        <div>
        <Container>
            <figure className="figure11">
                <img src={mainLogo5} classname="img-fluid" width={350}/> 
                <figcaption className='figure22'>
                    <div className='contactus'>Contact us at</div>
                    <div className='email'>TheMagicHouseBooks@gmail.com</div>
                </figcaption>
            </figure>
        </Container>
        </div>
        
        <div></div>

            <a href="https://www.tiktok.com/t/ZTdGVmG59/" target="_blank">
           <div className='tiktokp'><img src={tiktokp} width={30}/></div> </a>
           <a href="https://www.instagram.com/_u/themagichousebooks" target="_blank">
           <div className='instap'><img src={instap} width={30}/></div> </a>
           <a href="https://www.amazon.com/Dylan-French/e/B0B2JGDHKK/ref=dp_byline_cont_pop_book_1" target="_blank">
           <div className='ama'><img src={ama} width={30}/></div> </a>

           <div className='end'></div>


        </React.Fragment>

    )

    
}

export default Contact;
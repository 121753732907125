import React from 'react';
import Main from './components/MainComponent'
import {BrowserRouter} from 'react-router-dom'
import './css/styles.css'


function App() {
  return (
      <article class="grid">

      <div className="App">
      <BrowserRouter>
         <Main/>
      </BrowserRouter>
      </div>

      </article>
     
    

  );
}

export default App;


import React from 'react'
import {NavLink} from 'react-router-dom'
import mainLogo2 from '../img/Cover22.jpg'
import mainLogo3 from '../img/coverfrontduck.jpg'
import strawb from '../img/strawberry.png'
import ducky from '../img/Ducksolo.png'
import page1 from '../img/stwpage8-9.jpg'
import page2 from '../img/page2-3-2.jpg'
import page3 from '../img/page4-5.jpg'
import paged1 from '../img/duckpage-1-2.jpg'
import paged2 from '../img/duckpage-9-10.jpg'
import paged3 from '../img/duckpage-11-12.jpg'
import tiktokp from '../img/tt2.png'
import instap from '../img/insta.png'
import topGIF from '../img/Vanilla.svg'
import ama from '../img/ama.png'
import {useRef} from 'react'
import { configure } from '@testing-library/react'


function Bookstore(){
    const Duck = useRef(null);
    const Strawberry = useRef(null);

//need new const with unique "handle click" name to make a new scroll action :) 

  
  const handleClick2 = () => {
    Strawberry.current?.scrollIntoView({behavior: 'smooth'});
  };

  const handleClick = () => {
    Duck.current?.scrollIntoView({behavior: 'smooth'});
  };


    return(
        <React.Fragment>
             
            <div className='links'>
         <NavLink to='/home' className='mainnav'>Home{' '}</NavLink>
        <NavLink to='/bookstore' className='mainnav'>Bookstore{' '}</NavLink>
        <NavLink to='/author' className='mainnav'>Author{' '}</NavLink>
        <NavLink to='/contact' className='mainnav'>Contact{' '}</NavLink>
            </div>
       




        <div className='AboveBookstore'> 
        </div>
        <div><img src={topGIF} width={250}/></div>
        <div className='Duck1'>
            <ul>
            <li className='link' onClick={handleClick}><img src={mainLogo3} width={149}/></li>
    
            <li className='link' onClick={handleClick2}><img src={mainLogo2} width={147}/></li>


            </ul>



        </div>
        <div className='space1'></div>
        <div className='space1'></div>
        <div className='space1'></div>
        <div className='space1'></div>
        <div className='space1'></div>
        <div className='space1'></div>
        <div className='space1'></div>


        <div ref={Duck} className='Duck2'>
        
<div className='GIF'><img src={mainLogo3} width={290}/></div> 
<div className='space1'></div>
<a href="https://www.amazon.com/There-Once-Duck-Dylan-French/dp/B0BCSH4S1L/" target="_blank">
   <div className='buybutton1'>
<button className='buybutton'>
Get this book
</button>
</div> </a>
<div className='abovetitle'></div>
<div className='titlestrawberry'>There Once Was A Duck</div>
<div className='space1'></div>
<div>
There Once Was A Duck is a story about a Duck who takes a walk through New York. 
He doesn’t know exactly where he’s going… but he’s going somewhere! 
Follow him as he finds his way through the day and though the night 
(with the help of the moon’s light).</div>
    <div className='space1'></div>
    <div>Paperback</div> 
   <div>8.5 x 11 in.</div>
   <div>33 pages</div>
   <div>Art originally watercolor on cotton</div>
   <div>Publication date September 1st, 2022</div>
   <div className='space1'></div>
   <div className='strawb'><img src={ducky} width={111}/></div> 
   <div className='space1'></div>
   <div className='page1'><img src={paged1} width={300}/></div> 
   <div className='page2'><img src={paged2} width={300}/></div> 
   <div className='page3'><img src={paged3} width={300}/></div> 


            </div>
            <div className='space1'></div>
            <div className='space1'></div>
            <div className='space1'></div>
            <div className='space1'></div>
            <div className='space1'></div>
            <div className='space1'></div>
            <div className='space1'></div>
            <div className='space1'></div>
            <div className='space1'></div>

        <div ref={Strawberry} className='Strawberry'>

        <div className='GIF'><img src={mainLogo2} width={290}/></div> 
        <div className='space1'></div>
        <a href="https://www.amazon.com/Strawberry-Blues-Dylan-French/dp/B0B14JLJV4/" target="_blank">
           <div className='buybutton1'>
<button className='buybutton'>
    Get this book
</button>
</div> </a>
        <div className='abovetitle'></div>
        <div className='titlestrawberry'>The Strawberry Blues</div>
        <div className='space1'></div>
        <div>The Strawberry Blues is a fun-to-read, rhyming story about our inherent worth as people. 
            About our worthiness for love in our current condition, right now—today. 
            The story follows Strawberry as she feels unfortunately red in a world too blue. 
            She comes across a friend that helps her see the sunny side of things... 
            Reminding her that not only is she loved, but that she deserves to feel loved, too.</div>
            <div className='space1'></div>
            <div>Paperback</div> 
           <div>8.5 x 11 in.</div>
           <div>27 pages</div>
           <div>Art originally acrylic on canvas</div>
           <div>Publication date May 12th, 2022</div>
           <div className='space1'></div>
           <div className='strawb'><img src={strawb} width={111}/></div> 
           <div className='space1'></div>
        
           <div className='page2'><img src={page2} width={300}/></div> 
           <div className='page3'><img src={page3} width={300}/></div> 
           <div className='page1'><img src={page1} width={300}/></div> 
        
        </div>
         
           <div className='space1'></div>
           <div className='space1'></div>
           <div className='space1'></div>
           <div className='space1'></div>
           <div className='space1'></div>
           <div className='space1'></div>
    

        
            <a href="https://www.tiktok.com/t/ZTdGVmG59/" target="_blank">
           <div className='tiktokp'><img src={tiktokp} width={30}/></div> </a>
           <a href="https://www.instagram.com/_u/themagichousebooks" target="_blank">
           <div className='instap'><img src={instap} width={30}/></div> </a>
           <a href="https://www.amazon.com/Dylan-French/e/B0B2JGDHKK/ref=dp_byline_cont_pop_book_1" target="_blank">
           <div className='ama'><img src={ama} width={30}/></div> </a>

           <div className='end'></div>

            
            
        </React.Fragment>
      
    )
}

export default Bookstore;